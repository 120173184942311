import styled from "styled-components";
export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  /* height: calc(100vh - 50px); */
  padding-bottom: 50px;
  background-color: #f3f8fb;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    padding-bottom: 10px;
  }
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: baseline;
  width: 40%;
  padding: 20px;
  padding-left: 100px;
  margin-bottom: 60px;
  img {
    max-width: 100%;
  }
  @media screen and (max-width: 768px) {
    flex: 1 1 100%;
    width: auto;
    padding: 10px;
  }
`;

export const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    max-width: 100%;
    max-height: 64vh;
    object-fit: cover;
  }
  @media screen and (max-width: 768px) {
    width: 100%;
    &.hm {
      display: none;
    }
  }
`;
export const Header = styled.div`
  display: flex;
  padding: 10px;
  box-shadow: rgb(0 0 0 / 16%) 0px 5px 9px 0px;
  background-color: white;
  img {
    height: 30px;
  }
`;

export const Caption = styled.div`
  display: flex;
  flex-direction: column;
  h2 {
    margin-bottom: 0;
    margin-top: 50px;
  }
  p {
    display: flex;
    margin-bottom: 50px;
  }
`;
export const ParkingItem = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0;
  margin-bottom: 10px;
  border-radius: 10px;
  margin-right: 5px;
  margin-left: 20px;
  box-shadow: rgb(0 0 0 / 16%) 0px 0px 2px 0px;
  background-color: white;
  img {
    display: flex;
    width: 30%;
    object-fit: cover;
    border-radius: 10px 0 0 10px;
    cursor: pointer;
  }
  article {
    display: flex;
    flex-direction: column;
    width: 70%;
    padding: 10px 10px;
  }
  h2 {
    margin: 0 0 10px 0;
    font-size: 16px;
    color: #1181c5;
  }
  p {
    font-size: 14px;
    margin: 5px 0 0 0;
    justify-content: left;
    display: flex;
    flex-wrap: nowrap;
  }
  .pricing {
    font-weight: bold;
  }
  svg {
    margin-right: 5px;
    margin-top: 3px;
    width: 10px;
  }
  button {
    font-weight: bold;
    padding: 10px;
    border-radius: 10px;
  }
  @media (max-width: 768px) {
    margin-bottom: 10px;
    border-radius: 10px;
    margin-right: 5px;
    margin-left: 5px;
  }
`;
