// its middlewear to handle reducer call to update a state

import { getData } from "../../../backend/api";

const fetchSearchParkings = (data) => {
  return (dispatch) => {
    dispatch({
      type: "FETCH_SEARCH_PARKINGS_LOADING",
    });
    getData(data, `search-parking`)
      .then((response) => {
        if (response.status === 200) {
          dispatch({
            type: "FETCH_SEARCH_PARKINGS",
            payload: response.data,
          });
        } else {
          dispatch({
            type: "FETCH_SEARCH_PARKINGS_ERROR",
            payload: "validationFailed",
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: "FETCH_SEARCH_PARKINGS_ERROR",
          payload: error,
        });
      });
  };
};
const clearSearchParkings = () => {
  return (dispatch) => {
    dispatch({
      type: "CLEAR_SEARCH_PARKINGS",
    });
  };
};
export { fetchSearchParkings, clearSearchParkings };
