import React, { useEffect, useState } from "react";
// import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
// import { postData } from "../../../backend/api";
import AutoForm from "../../elements/form";
import Footer from "../footer";
import { Caption, FormContainer, MainContainer } from "./styles";
//using translation
import { useTranslation } from "react-i18next";
// import { clearVehicle, fetchVehicle } from "../../../store/actions/vehicle";
import { useDispatch, useSelector } from "react-redux";
import withLayout from "../layout";
import { ColumnContainer, Points, RowContainer } from "../../styles/containers/styles";
import Header from "../header";
import { Tab, TabContainer, TabHeader, TabLink } from "../../private/pages/start/styels";
import { banner, f1, f2, f3 } from "../../../images";

const Login = (props) => {
  useEffect(() => {
    document.title = `Rental Portal`;
  }, []);
  const [activeTab, setActiveTab] = useState("parking-hourly");
  const themeColors = useSelector((state) => state.themeColors);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const vehicle = useSelector((state) => state.vehicle);
  const { t } = useTranslation();
  const [hourly] = useState([
    {
      type: "search",
      placeholder: "parkingAt",
      name: "parkingAt",
      validation: "text",
      default: "",
      label: "parkingAt",
      minimum: 5,
      maximum: 200,
      required: true,
      apiType: "API",
      selectApi: "cities",
    },
    {
      type: "datetime",
      placeholder: "parkingFrom",
      name: "parkingFrom",
      validation: "parkingFrom",
      default: "",
      label: "parkingFrom",
      minimum: 0,
      maximum: 30,
      required: true,
      half: true,
    },
    {
      type: "datetime",
      placeholder: "parkingUntil",
      name: "parkingUntil",
      validation: "greater",
      reference: "parkingFrom",
      default: "",
      label: "parkingUntil",
      minimum: 0,
      maximum: 30,
      required: true,
      half: true,
    },
  ]);
  const [monthly] = useState([
    {
      type: "search",
      placeholder: "parkingAt",
      name: "parkingAt",
      validation: "text",
      default: "",
      label: "parkingAt",
      minimum: 5,
      maximum: 200,
      required: true,
      apiType: "API",
      selectApi: "cities",
    },
    {
      type: "date",
      placeholder: "parkingFrom",
      name: "parkingFrom",
      validation: "",
      default: "",
      label: "parkingFrom",
      minimum: 0,
      maximum: 30,
      required: true,
      half: true,
    },
    {
      type: "number",
      placeholder: "duration",
      name: "duration",
      validation: "duration",
      default: "",
      label: "duration",
      minimum: 30,
      maximum: 90,
      required: true,
      apiType: "CSV",
      selectApi: "Seven Days Per Week,Mon-Fri",
      half: true,
    },
  ]);
  const { setLoaderBox } = props;
  useEffect(() => {
    setLoaderBox(vehicle.isLoading);
    if (vehicle.error !== null) {
      props.setMessage({ type: 1, content: t(vehicle.error), proceed: "Okay" });
      // dispatch(clearVehicle());
    }
  }, [vehicle, navigate, setLoaderBox, t, props, dispatch]);

  const isCreatingHandler = (value, callback) => {};
  const submitChange = async (post) => {
    // dispatch(fetchVehicle(post));
    const queryParams = new URLSearchParams({ ...post, activeTab });
    // redirect to parking page with query parameters
    navigate(`/search?${queryParams.toString()}`);
  };

  return (
    <MainContainer className="notfixed">
      <Header> </Header>
      <RowContainer
        style={{
          backgroundImage: `linear-gradient(to right,  #f1f6f8 30%, transparent),url(${banner})`,
          backgroundSize: "cover",
        }}
      >
        <FormContainer>
          <Caption>
            <h2>Find Parking In A Second</h2>
            <p>Choose from many available spots or reserve a parking lot in advance. Book easily and cheaply.</p>
          </Caption>
          <TabContainer>
            <TabHeader>
              <TabLink theme={themeColors} className={activeTab === "parking-hourly" && "active"} onClick={() => setActiveTab("parking-hourly")}>
                {t("hourlyDaily")}
              </TabLink>
              <TabLink theme={themeColors} className={activeTab === "parking-monthly" && "active"} onClick={() => setActiveTab("parking-monthly")}>
                {t("monthly")}
              </TabLink>
            </TabHeader>
            {/* tab for parking process */}
            <Tab active={activeTab === "parking-hourly"}>
              <RowContainer>
                <AutoForm useCaptcha={false} formType={"post"} key={"hourly"} formInput={hourly} submitHandler={submitChange} button={t("showParking")} isOpenHandler={isCreatingHandler} isOpen={true} customCss={`plain`}></AutoForm>
              </RowContainer>
            </Tab>
            {/* tab for parking rules */}
            <Tab active={activeTab === "parking-monthly"}>
              <RowContainer>
                <ColumnContainer>
                  <AutoForm key={"monthly"} useCaptcha={false} formType={"post"} formInput={monthly} submitHandler={submitChange} button={t("showParking")} isOpenHandler={isCreatingHandler} isOpen={true} customCss={`plain`}></AutoForm>
                </ColumnContainer>
              </RowContainer>
            </Tab>
          </TabContainer>
        </FormContainer>
      </RowContainer>
      <RowContainer className="features">
        <RowContainer className="center container">
          <h2>Parking Easy And Fast</h2>
        </RowContainer>
        <ColumnContainer className="container">
          <Points>
            <img alt="F1" src={f1}></img>
            <h4>Find Your Parking Lot</h4>
            <p>
              Simply find and book a parking space by the hours, day or month via the innopark24 rent portal.
              <br /> <br />
              Just follow our steps
            </p>
          </Points>
          <Points>
            <img alt="F1" src={f2}></img>
            <h4>Our Parking System</h4>
            <p>
              We record vour License Plate automatically and in compliance with data protection when entering and and exiting.
              <br /> <br />
              With us vou don't need any barriers or parking tickets.
            </p>
          </Points>
          <Points>
            <img alt="F1" src={f3}></img>
            <h4>Environmental Protection</h4>
            <p>
              With a booking on our portal, the annoying search for a parking space is over. <br /> <br /> Our goal is to reduce CO2 emissions. <br /> <br />
              Environmental protection is important to us.
            </p>
          </Points>
        </ColumnContainer>
      </RowContainer>
      <Footer fixed={false}></Footer>
    </MainContainer>
  );
};

export default withLayout(Login);
