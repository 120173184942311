import React, { useCallback, useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import AutoForm from "../../elements/form";
import { ColumnContainer, RowContainer, SlidePopup } from "../../styles/containers/styles";
import Footer from "../footer";
import Header from "../header";
import { MainContainer } from "../login/styles";
import { ParkingItem } from "./styles";
import { DollarIcon, LocationIcon } from "../../../icons";
import { Button } from "../../elements/input/styles";
import { useDispatch, useSelector } from "react-redux";
import { fetchSearchParkings } from "../../../store/actions/search";
import withLayout from "../layout";
import { icon } from "../../../images";
import { ListItem, ListItemHead } from "../../styles/list/styles";
import { formatAmount } from "../../functions/formatCurrecny";

const SearchPage = (props) => {
  // extract query parameters from location
  const [searchParams, setSearchParams] = useSearchParams();
  const parkingAt = searchParams.get("parkingAt");
  const parkingFrom = searchParams.get("parkingFrom"); // default value is today
  const parkingUntil = searchParams.get("parkingUntil"); // default value is 7 days from today
  const duration = searchParams.get("duration"); // default value is 7 days from today
  const activeTab = searchParams.get("activeTab"); // get search type
  // validate query parameters
  const [isLoaded, setIsLoaded] = useState(0);
  const [searchSettings, setSearchSettings] = useState();
  const [parkingSelected] = useState(null);
  const locations = useSelector((state) => state.parkings);
  const themeColors = useSelector((state) => state.themeColors);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const submitChange = useCallback(
    async (post, formState = [], onLoad = false) => {
      // Parse the parkingAt string into an object
      const location = JSON.parse(post.parkingAt);
      // Create a new array with the lat, lon, and location properties
      let mainArray = { lat: location.lat, lng: location.lng, activeTab, parkingFrom: post.parkingFrom };
      mainArray = activeTab === "parking-hourly" ? { ...mainArray, parkingUntil: parkingUntil } : { ...mainArray, duration: duration };
      setSearchSettings(mainArray);
      // console.log(mainArray)
      if (onLoad) {
        dispatch(fetchSearchParkings(mainArray));
      } else {
        setSearchParams(new URLSearchParams({ ...post, activeTab }));
        dispatch(fetchSearchParkings(mainArray));
      }
    },
    [dispatch, activeTab, duration, parkingUntil, setSearchParams]
  );
  const handleSubmitChange = useCallback(() => {
    submitChange({ parkingAt, parkingFrom, parkingUntil }, [], true);
  }, [submitChange, parkingAt, parkingFrom, parkingUntil]);

  useEffect(() => {
    if (parkingAt !== isLoaded) {
      setIsLoaded(parkingAt);
      handleSubmitChange();
    }
  }, [isLoaded, parkingAt, handleSubmitChange]);
  const mapRef = useRef(null);

  useEffect(() => {
    if (locations.data.length > 0) {
      const coords = locations.data;
      const location = JSON.parse(parkingAt);
      mapRef.current = new window.google.maps.Map(document.getElementById("map"), {
        center: { lat: location.lat, lng: location.lng },
        zoom: 15,
      });
      new window.google.maps.Marker({
        position: { lat: location.lat, lng: location.lng, title: location.location },
        map: mapRef.current,
      });
      coords.forEach((coord) => {
        // Create a marker for each location
        const marker = new window.google.maps.Marker({
          position: coord,
          map: mapRef.current,
          // Customize the marker icon
          icon: {
            url: icon,
            scaledSize: new window.google.maps.Size(35, 50),
          },
        });

        //Create a info window for each marker
        const infoWindow = new window.google.maps.InfoWindow({
          content: `
              <div>
              <img src="${coord.imageUrl}" alt="${coord.address}"></img>
                <h3>${coord.title}</h3>
                <p>Price: ${coord.price} EUR</p>
              </div>
            `,
        });

        // Add a click event listener to each marker to open its info window
        marker.addListener("click", () => {
          infoWindow.open(mapRef.current, marker);
          //openParking(coord);
        });
      });
    }
  }, [locations, parkingAt]);

  const [hourly] = useState([
    { type: "search", placeholder: "parkingAt", name: "parkingAt", validation: "text", default: parkingAt, label: "parkingAt", minimum: 5, maximum: 200, required: true, apiType: "API", selectApi: "cities" },
    { type: "datetime", placeholder: "parkingFrom", name: "parkingFrom", validation: "parkingFrom", default: parkingFrom, label: "parkingFrom", minimum: 0, maximum: 30, required: true, half: true },
    { type: "datetime", placeholder: "parkingUntil", name: "parkingUntil", validation: "greater", reference: "parkingFrom", default: parkingUntil, label: "parkingUntil", minimum: 0, maximum: 30, required: true, half: true },
  ]);
  const [monthly] = useState([
    { type: "search", placeholder: "ParkingAt", name: "ParkingAt", validation: "text", default: parkingAt, label: "ParkingAt", minimum: 5, maximum: 200, required: true, apiType: "API", selectApi: "cities" },
    { type: "date", placeholder: "parkingFrom", name: "parkingFrom", validation: "parkingFrom", default: parkingFrom, label: "parkingFrom", minimum: 0, maximum: 30, required: true, half: true },
    { type: "number", placeholder: "duration", name: "duration", validation: "duration", default: duration, label: "duration", minimum: 30, maximum: 90, required: true, apiType: "CSV", selectApi: "Seven Days Per Week,Mon-Fri", half: true },
  ]);
  // const openParking = (data) => {
  //   setParkingSelected(data);
  // };
  const bookNow = (data) => {
    const { parkingId } = data;
    const queryParams = new URLSearchParams(searchSettings);
    // redirect to parking page with query parameters
    navigate(`/checkout/${parkingId}/?${queryParams.toString()}`);
  };
  const { setLoaderBox } = props;
  useEffect(() => {
    setLoaderBox(locations.isLoading);
  }, [locations, setLoaderBox]);
  const isCreatingHandler = (value, callback) => {};
  return (
    <MainContainer>
      <Header className="conatiner"> </Header>
      <RowContainer>
        <ColumnContainer className="container">{activeTab === "parking-hourly" ? <AutoForm useCaptcha={false} formType={"post"} key={"hourly"} formInput={hourly} submitHandler={submitChange} button={t("search")} isOpenHandler={isCreatingHandler} isOpen={true} customCss={"nowrap"}></AutoForm> : <AutoForm useCaptcha={false} formType={"post"} key={"monthly"} formInput={monthly} submitHandler={submitChange} button={t("search")} isOpenHandler={isCreatingHandler} isOpen={true} customCss={"nowrap"}></AutoForm>}</ColumnContainer>
      </RowContainer>
      <ColumnContainer className="container">
        <RowContainer className="booking">
          {(locations?.data ? (locations.data.length > 0 ? true : false) : false) === true ? (
            locations.data.map((location) => (
              <ParkingItem key={location.address}>
                <img
                  onClick={() => {
                    if (mapRef.current) {
                      // Check if mapRef.current already exists
                      mapRef.current.setCenter({ lat: location.lat, lng: location.lng }); // Change the center of the map
                      mapRef.current.setZoom(15); // Set the zoom level
                    } else {
                      mapRef.current = new window.google.maps.Map(document.getElementById("map"), {
                        center: { lat: location.lat, lng: location.lng },
                        zoom: 15,
                      });
                    }
                  }}
                  src={location.imageUrl}
                  alt={location.address}
                ></img>

                <article>
                  <h2>{location.title}</h2>
                  <p>
                    <LocationIcon />
                    {location.address}
                  </p>
                  <p className="pricing">
                    <DollarIcon />
                    {`${formatAmount(location.price)} / ${t('hour')}`}
                  </p>
                  <Button
                    theme={themeColors}
                    className="submit"
                    onClick={() => {
                      bookNow(location);
                    }}
                  >
                    {`${t("book")}`}
                  </Button>
                </article>
              </ParkingItem>
            ))
          ) : (
            <ParkingItem key="no-parking">
              {" "}
              <article>
                <h2>Didn't find a parking space? Click here and write us a message here and we will try to find a parking space near you! </h2>
              </article>
            </ParkingItem>
          )}
          {parkingSelected && (
            <SlidePopup>
              <img src={parkingSelected.imageUrl} alt={parkingSelected.address}></img>
              <article>
                <ListItemHead>{parkingSelected.title} </ListItemHead>
                <ListItem className="row">
                  <LocationIcon />
                  {parkingSelected.address}
                </ListItem>
                <ListItem>
                  <ColumnContainer>
                    <span>Hourly Rate </span>
                    <span>{`€${formatAmount(parkingSelected.price)}`}</span>
                  </ColumnContainer>
                </ListItem>
                <Button theme={themeColors} className="submit" onClick={() => {}}>
                  {"Confirm Payment"}
                </Button>
              </article>
            </SlidePopup>
          )}
        </RowContainer>
        <RowContainer className="map">
          <div className="map-container" id="map">
            {mapRef.current && <div>Map is loaded!</div>}
          </div>
          {/* <iframe title="new" allow="touchmove" src={locations.data[0]?.mapUrl ? locations.data[0].mapUrl : null} height="450" style={{ border: 0 }} loading="lazy"></iframe> */}
        </RowContainer>
      </ColumnContainer>

      <Footer className="conatiner"></Footer>
    </MainContainer>
  );
};

export default withLayout(SearchPage);
