import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { FaqIcon, LocationIcon } from "../../../icons";
import { Section, Tab, TabContainer, TabHeader, TabLink } from "../../private/pages/start/styels";
import { ColumnContainer, RowContainer } from "../../styles/containers/styles";
import { ListItem, ListItemHead } from "../../styles/list/styles";
import Footer from "../footer";
import Header from "../header";
import withLayout from "../layout";
import { MainContainer } from "../login/styles";
import { InfoBox, ParkingItem } from "../booking/styles";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { fetchCheckout } from "../../../store/actions/checkout";
import moment from "moment";
import AutoForm from "../../elements/form";
import { postData } from "../../../backend/api";
import { credit, sepa } from "../../../images";
const BookingPage = (props) => {
  const location = useLocation();
  // extract query parameters from location
  const queryParams = new URLSearchParams(location.search);
  const lat = queryParams.get("lat");
  const lng = queryParams.get("lng");
  const parkingFrom = queryParams.get("parkingFrom"); // default value is today
  const parkingUntil = queryParams.get("parkingUntil"); // default value is 7 days from today
  const duration = queryParams.get("duration"); // default value is 7 days from today
  const activeTab = queryParams.get("activeTab"); // get search type
  const parkingLocation = queryParams.get("location"); // get search type
  const checkoutData = useSelector((state) => state.parkingDetails);
  const [parkingPlace] = useState({ parkingId: 10001, title: "Parkhaus am Zoo", address: "Bernhard-Grzimek-Allee 1, 60316 Frankfurt am Main, Germany", price: 3, imageUrl: "/parking/1.jpg", mapUrl: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2560.848539909563!2d8.678602315734273!3d50.12001887942012!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47bd0f47d12df05d%3A0x7a073f0c41e7d045!2sParkhaus%20am%20Zoo!5e0!3m2!1sen!2sus!4v1647858778555!5m2!1sen!2sus" });
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { parkingId } = useParams();
  const [paymentType, setPaymentType] = useState("creditcard");
  const themeColors = useSelector((state) => state.themeColors);
  const fields = [{ type: "text", placeholder: "cardHolder", name: "cardHolder", validation: "text", default: "", label: "cardHolder", minimum: 2, maximum: 30, required: true }];
  const [paymentForm] = useState([{ type: "text", placeholder: "ccn", name: "ccn", validation: "ccn", default: "4005520000000129", label: "ccn", minimum: 0, maximum: 16, required: true }, { type: "text", placeholder: "expiry", name: "expiry", validation: "expiry", default: "10/24", label: "expiry", minimum: 0, maximum: 5, half: true, required: true }, { type: "password", placeholder: "cvv", name: "cvv", validation: "cvv", default: "002", label: "cvv", minimum: 0, maximum: 3, half: true, required: true }, ...fields]);
  const [sepaForm] = useState([{ type: "text", placeholder: "iban", name: "iban", validation: "iban", default: "DE34500100600032121604", label: "iban", minimum: 0, maximum: 25, required: true }, ...fields]);
  const [license] = useState([{ type: "text", placeholder: "licensePlate", name: "licensePlate", validation: "licensePlate", default: "", label: "licensePlate", minimum: 0, maximum: 20, required: true }]);
  const [email] = useState([{ type: "text", placeholder: "email", name: "email", validation: "email", default: "", label: "email", minimum: 0, maximum: 120, required: true }]);
  //form status
  const [licenseStatus, setLicenseStatus] = useState(false);
  const [licenseData, setLicenseData] = useState({ licensePlate: "" });
  const licenseHandler = (status, value = { licensePlate: "" }) => {
    setLicenseStatus(status);
    setLicenseData(value);
  };
  const [emailStatus, setEmailStatus] = useState(false);
  const [emailData, setEmailData] = useState({ email: "" });
  const emailDataHandler = (status, value = { email: "" }) => {
    setEmailStatus(status);
    setEmailData(value);
  };
  // validate query parameters
  const [isLoaded, setIsLoaded] = useState(false);
  useEffect(() => {
    if (!isLoaded) {
      let mainArray = { parkingFrom };
      mainArray = activeTab === "parking-hourly" ? { ...mainArray, parkingUntil } : { ...mainArray, duration };

      dispatch(fetchCheckout(parkingId, mainArray));
      setIsLoaded(true);
    }
  }, [isLoaded, lat, lng, parkingFrom, parkingUntil, activeTab, dispatch, parkingId, duration, parkingLocation]);
  const { setLoaderBox } = props;
  const payNow = async (post) => {
    props.setLoaderBox(true);
    const paymentData = { ...post, amount: checkoutData.data.price };
    await postData(paymentData, "payment/credit")
      .then((response) => {
        if (response.status === 200) {
          // setFormInput(initialFormInout);
          props.setLoaderBox(response.message);
        } else {
          props.setMessage({ type: 1, content: response.message, proceed: "Try Again" });
        }
        props.setLoaderBox(false);
      })
      .catch((error) => {
        props.setMessage({ type: 1, content: "An error occurred on the server. Please try again later or contact support if the issue persists.", proceed: "Try Again" });
        props.setLoaderBox(false);
      });
  };
  const paySepa = async (post) => {
    props.setLoaderBox(true);
    const paymentData = { ...post, ...licenseData, ...emailData, amount: checkoutData.data.price };
    await postData(paymentData, "payment/sepa")
      .then((response) => {
        if (response.status === 200) {
          // setFormInput(initialFormInout);
          props.setLoaderBox(response.message);
        } else {
          props.setMessage({ type: 1, content: response.message, proceed: "Try Again" });
        }
        props.setLoaderBox(false);
      })
      .catch((error) => {
        props.setMessage({ type: 1, content: "An error occurred on the server. Please try again later or contact support if the issue persists.", proceed: "Try Again" });
        props.setLoaderBox(false);
      });
  };
  const isCreatingHandler = () => {};
  useEffect(() => {
    setLoaderBox(checkoutData.isLoading);
  }, [checkoutData, setLoaderBox]);
  const calculateMinutes = () => {
    const diffInMs = Math.abs(new Date(parkingUntil) - new Date(parkingFrom));
    const diffInHours = Math.floor(diffInMs / (1000 * 60 * 60));
    return diffInHours;
  };
  return (
    <MainContainer className="notfixed">
      <Header> </Header>
      <ColumnContainer>
        <h1>{t('confirmParking')}</h1>
      </ColumnContainer>
      <ColumnContainer className="container">
        <RowContainer className="width60">
          <ColumnContainer>
            <Section className="white">
              <ParkingItem>
                <img src={checkoutData.data.imageUrl} alt={checkoutData.data.address}></img>
                <ListItemHead>
                  <div> {t("parkingInformation")}</div> <p>Here you will find the information about parking</p>
                </ListItemHead>
                <ListItemHead>
                  <div>{t('importantNote')}</div>
                  <p className="box">Lorem ipsum dolor Sit amet Consectetur. LectuS malesuada nisl porttitOr quis turpiS neque facilisis. Amet amet orci Odio tincidunt_ Sed massa libero gravida_ proin aliquet non turpis aliquam tempus tellus vestibulum in. Donec aliquam feu8iat</p>
                </ListItemHead>
              </ParkingItem>
            </Section>
          </ColumnContainer>
          <ColumnContainer>
            <Section className="white ">
              <ParkingItem key={checkoutData.data.address}>
                <ListItemHead>
                  <div>{t("bookingDetails")}</div> <p>{t("bookingCheck")}</p>
                </ListItemHead>

                <article>
                  <ListItemHead>{checkoutData.data.title} </ListItemHead>
                  <ListItem className="row">
                    <LocationIcon />
                    {parkingPlace.address}
                  </ListItem>
                  <ListItem>
                    <ColumnContainer>
                      <span>{t("hourlyRate")} </span>
                      <span>
                        {(checkoutData.data.price / 100).toLocaleString("de-DE", {
                          style: "currency",
                          currency: "EUR",
                          currencyDisplay: "code",
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 2,
                        })}
                      </span>
                    </ColumnContainer>
                    {/* <ColumnContainer>
                      <span>{t("totalHours")} </span>
                      <span>{`${2} EUR`}</span>
                    </ColumnContainer> */}

                    {/* <ColumnContainer>
                      <span>{t("bookingType")}</span>
                      <span>{activeTab === "parking-monthly" ? t("monthly") : t("hourlyDaily")}</span>
                    </ColumnContainer> */}
                    <ColumnContainer>
                      <span>{t("arrivingOn")}</span>
                      <span>{moment(parkingFrom).format("DD.MM.YYYY HH:mm")}</span>
                    </ColumnContainer>
                    {activeTab !== "parking-monthly" && (
                      <ColumnContainer>
                        <span>{t("leavingOn")}</span>
                        <span>{moment(parkingUntil).format("DD.MM.YYYY HH:mm")}</span>
                      </ColumnContainer>
                    )}
                    {activeTab === "parking-hourly" && (
                      <ColumnContainer>
                        <span>{t("duration")}</span>
                        <span>{`${calculateMinutes()} ${t("hours")}`}</span>
                      </ColumnContainer>
                    )}
                    {activeTab === "parking-monthly" && (
                      <ColumnContainer>
                        <span>{t("duration")}</span>
                        <span>{`${duration} ${t("days")}`}</span>
                      </ColumnContainer>
                    )}
                    <ColumnContainer>
                      <span>{t("totalCost")} </span>
                      <span>
                        {(350 / 100).toLocaleString("de-DE", {
                          style: "currency",
                          currency: "EUR",
                          currencyDisplay: "code",
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </span>
                    </ColumnContainer>
                  </ListItem>
                </article>
              </ParkingItem>
            </Section>
          </ColumnContainer>
        </RowContainer>
        <RowContainer className="width40">
          {checkoutData.data.price ? (
            <>
              <Section className="no-shadow">
                <RowContainer>
                  <AutoForm formValidated={licenseHandler} buttonStyle={`hide`} useCaptcha={false} description={`1. ${t("licenseHead")}`} formType={"post"} key={"payment"} formInput={license} submitHandler={payNow} button={t("next")} isOpenHandler={isCreatingHandler} isOpen={true} customCss={`plain`}></AutoForm>
                </RowContainer>
              </Section>
              <Section className="no-shadow">
                <RowContainer>
                  <AutoForm formValidated={emailDataHandler} buttonStyle={`hide`} useCaptcha={false} description={`2. ${t("emailHead")}`} formType={"post"} key={"payment"} formInput={email} submitHandler={payNow} button={t("next")} isOpenHandler={isCreatingHandler} isOpen={true} customCss={`plain`}></AutoForm>
                </RowContainer>
              </Section>
              <Section className="no-shadow payment">
                <TabContainer className="">
                  <TabHeader>
                    <TabLink theme={themeColors} className={paymentType === "creditcard" && "active"} onClick={() => setPaymentType("creditcard")}>
                      <img src={credit} alt="credit" />
                    </TabLink>
                    <TabLink theme={themeColors} className={paymentType === "sepa" && "active"} onClick={() => setPaymentType("sepa")}>
                      <img src={sepa} alt="sepa" />
                    </TabLink>
                  </TabHeader>
                  {/* tab for parking process */}
                  <Tab className="payment" active={paymentType === "creditcard"}>
                    <RowContainer>
                      {emailStatus && licenseStatus ? (
                        <AutoForm useCaptcha={false} formType={"post"} key={"payment"} formInput={paymentForm} submitHandler={payNow} button={t("confirmPayment")} isOpenHandler={isCreatingHandler} isOpen={true} customCss={`plain`}></AutoForm>
                      ) : (
                        <InfoBox>
                          <FaqIcon></FaqIcon>
                          {t("paymentValidation")}
                        </InfoBox>
                      )}
                    </RowContainer>
                  </Tab>
                  {/* tab for parking rules */}
                  <Tab active={paymentType === "sepa"}>
                    <RowContainer>
                      <ColumnContainer>
                        {emailStatus && licenseStatus ? (
                          <AutoForm useCaptcha={false} formType={"post"} key={"payment"} formInput={sepaForm} submitHandler={paySepa} button={t("confirmPayment")} isOpenHandler={isCreatingHandler} isOpen={true} customCss={`plain`}></AutoForm>
                        ) : (
                          <InfoBox>
                            <FaqIcon></FaqIcon> {t("paymentValidation")}
                          </InfoBox>
                        )}
                      </ColumnContainer>
                    </RowContainer>
                  </Tab>
                </TabContainer>
              </Section>
            </>
          ) : (
            "Loading"
          )}
        </RowContainer>
      </ColumnContainer>
      <Footer fixed={false}></Footer>
    </MainContainer>
  );
};

export default withLayout(BookingPage);
