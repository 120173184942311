import React, { useEffect, useRef, useState } from "react";
import CustomSelect from "../select";
import moment from "moment";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Button, CheckBox, DatetimeInput, FileContainer, Input, InputContainer, Label, ShowDate, TextArea } from "./styles";
import { ErrorMessage } from "../form/styles";
import { LocationIcon, TickIcon, UploadIcon } from "../../../icons";

function FormInput(props) {
  const formatDateTime = (type, value) => {
    // const today = moment().startOf("day");
    // const tomorrow = moment().startOf("day").add(1, "days");
    const date = moment(value);

    if (type === "datetime") {
      // if (date.isSame(today, "day")) {
      //   return "Today, " + date.format("h:mm A");
      // } else if (date.isSame(tomorrow, "day")) {
      //   return "Tomorrow, " + date.format("h:mm A");
      // } else {
        return date.format("DD.MM.YYYY HH:mm");
      // }
    } else if (type === "date") {
      // if (date.isSame(today, "day")) {
      //   return "Today";
      // } else if (date.isSame(tomorrow, "day")) {
      //   return "Tomorrow";
      // } else {
        return date.format("DD.MM.YYYY");
      // }
    }
  };
  const [place, setPlace] = useState("");
  const [placeSet, setPlaceSet] = useState(false);
  const timerRef = useRef(null);
  useEffect(() => {
    return () => {
      clearTimeout(timerRef.current);
    };
  }, []);

  const [locations, setLocations] = useState([]);
  const [autocompleteService, setAutocompleteService] = useState(null);
  const [placesService, setPlacesService] = useState(null);
  const initializeAutocompleteService = () => {
    if (autocompleteService === null || placesService === null) {
      const autocompleteServiceTemp = new window.google.maps.places.AutocompleteService();
      const placesServiceTemp = new window.google.maps.places.PlacesService(document.createElement("div"));
      setAutocompleteService(autocompleteServiceTemp);
      setPlacesService(placesServiceTemp);
    }
  };
  const locationSearch = async (value) => {
    if (value.length > 1) {
      if (autocompleteService) {
        autocompleteService.getPlacePredictions(
          {
            input: value,
            componentRestrictions: { country: "de" },
            fields: ["geometry"],
          },
          (results) => {
            setLocations(results);
          }
        );
      }
    } else {
      setLocations([]);
    }
  };

  // Initialize translation function for current language
  const { t } = useTranslation();
  // Create a reference to file input element
  const fileInputRef = useRef(null);
  // Get theme colors from Redux store
  const themeColors = useSelector((state) => state.themeColors);

  switch (props.type) {
    // Render a regular text input
    case "text":
    case "password":
    case "email":
      return (
        <InputContainer className={props.half && "half"} animation={props.animation}>
          {props.error?.length ? (
            <Label theme={themeColors} className={`${!props.value.length > 0 ? "error shrink" : "error"}`}>
              {props.error}
            </Label>
          ) : (
            <Label theme={themeColors} className={`${!props.value.length > 0 ? "shrink" : ""}`}>
              <TickIcon />
              {`${t(props.label)}${props.required ? " *" : ""}`}
            </Label>
          )}
          <Input maxLength={props.maximum} autoComplete="on" theme={themeColors} className={`input ${props.value.length > 0 ? "shrink" : ""}`} placeholder={`${t(props.placeholder)}${props.required ? " *" : ""}`} type={props.type} value={props.value} onChange={(event) => props.onChange(event, props.id, props.type, props.sub)} />
          {/* {props.error?.length > 0 && <ErrorMessage dangerouslySetInnerHTML={{ __html: props.error }}></ErrorMessage>} */}
        </InputContainer>
      );
      case "number":
      return (
        <InputContainer className={props.half && "half"} animation={props.animation}>
          {props.error?.length ? (
            <Label theme={themeColors} className={`${!props.value.length > 0 ? "error shrink" : "error"}`}>
              {props.error}
            </Label>
          ) : (
            <Label theme={themeColors} className={`${!props.value.length > 0 ? "shrink" : ""}`}>
              <TickIcon />
              {`${t(props.label)}${props.required ? " *" : ""}`}
            </Label>
          )}
          <Input min={props.minimum} max={props.maximum} autoComplete="on" theme={themeColors} className={`input ${props.value.length > 0 ? "shrink" : ""}`} placeholder={`${t(props.placeholder)}${props.required ? " *" : ""}`} type={props.type} value={props.value} onChange={(event) => props.onChange(event, props.id, props.type, props.sub)} />
          {/* {props.error?.length > 0 && <ErrorMessage dangerouslySetInnerHTML={{ __html: props.error }}></ErrorMessage>} */}
        </InputContainer>
      );
    case "search":
      const location = props.value === "" ? { location: "" } : JSON.parse(props.value);

      if (location.location ? location.location : "") {
        if (!placeSet) {
          setPlaceSet(true);
          setPlace(location.location);
        }
      }
      return (
        <InputContainer className={props.half && "half"} animation={props.animation}>
          {props.error?.length ? (
            <Label theme={themeColors} className={`${!props.value.length > 0 ? "error shrink" : "error"}`}>
              {props.error}
            </Label>
          ) : (
            <Label theme={themeColors} className={`${!props.value.length > 0 ? "shrink" : ""}`}>
              <TickIcon />
              {`${t(props.label)}${props.required ? " *" : ""}`}
            </Label>
          )}
          <Input
            autoComplete="on"
            theme={themeColors}
            value={place}
            className={`input ${props.value.length > 0 ? "shrink" : ""}`}
            placeholder={`${t(props.placeholder)}${props.required ? " *" : ""}`}
            type={props.type}
            onFocus={initializeAutocompleteService}
            onChange={(event) => {
              if (place !== event.target.value) {
                setPlace(event.target.value);
                clearTimeout(timerRef.current);
                timerRef.current = setTimeout(() => {
                  locationSearch(event.target.value);
                }, 500);
              }
            }}
          />

          {(locations ? (locations.length > 0 ? true : false) : false) === true && (
            <ul className="options">
              {locations.map((location) => (
                <li
                  key={location.place_id}
                  onClick={(event) => {
                    const request = {
                      placeId: location.place_id,
                      fields: ["geometry"],
                    };

                    placesService.getDetails(request, (place, status) => {
                      if (status === window.google.maps.places.PlacesServiceStatus.OK) {
                        const lat = place.geometry.location.lat();
                        const lng = place.geometry.location.lng();
                        props.onChange({ lat: lat, lng: lng, location: location.description }, props.id, props.type);
                        setPlace(location.description);
                        setLocations([]);
                      } else {
                        console.error("Error fetching place details:", status);
                      }
                    });
                  }}
                >
                  <LocationIcon></LocationIcon> {location.description}
                </li>
              ))}
            </ul>
          )}
          {/* {props.error?.length > 0 && <ErrorMessage dangerouslySetInnerHTML={{ __html: props.error }}></ErrorMessage>} */}
        </InputContainer>
      );
    // Render a time input with time picker
    case "time":
      let userFriendlyTime = props.value.length > 0 ? formatDateTime("time", new Date(props.value)) : null;
      return (
        <InputContainer className={props.half && "half"}>
          <DatetimeInput
            theme={themeColors}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={15}
            timeCaption="Time"
            selected={userFriendlyTime}
            dateFormat="h:mm aa"
            minDate={new Date()} // set minimum date to today
            className={`input ${props.value.length > 0 ? "shrink" : ""}`}
            placeholderText={t(props.placeholder)}
            type={props.type}
            onChange={(event) => props.onChange(event, props.id, props.type)}
          />
          {props.error?.length ? (
            <Label theme={themeColors} className={`${!props.value.length > 0 ? "error shrink" : "error"}`}>
              {props.error}
            </Label>
          ) : (
            <Label theme={themeColors} className={`${!props.value.length > 0 ? "shrink" : ""}`}>
              <TickIcon />
              {`${t(props.label)}${props.required ? " *" : ""}`}
            </Label>
          )}
        </InputContainer>
      );
    // Render a date input with date picker
    case "date":
      let userFriendlyDate = props.value.length > 0 ? new Date(props.value) : null;
      return (
        <InputContainer className={props.half && "half"}>
          <DatetimeInput
            theme={themeColors}
            className={`input ${props.value.length > 0 ? "shrink" : ""}`}
            placeholderText={`${t(props.label)}${props.required ? " *" : ""}`}
            type={props.type}
            value={userFriendlyDate}
            selected={userFriendlyDate}
            minDate={new Date()} // set minimum date to today
            onChange={(event) => props.onChange(event, props.id, props.type)}
          />
          {props.error?.length ? (
            <Label theme={themeColors} className={`${!props.value.length > 0 ? "error shrink" : "error"}`}>
              {props.error}
            </Label>
          ) : (
            <>
              <Label theme={themeColors} className={`${!props.value.length > 0 ? "shrink" : ""}`}>
                <TickIcon />
                {`${t(props.label)}${props.required ? " *" : ""}`}
              </Label>
              {props.value.length > 0 && <ShowDate>{formatDateTime(props.type, userFriendlyDate)}</ShowDate>}
            </>
          )}
        </InputContainer>
      );
    // Render a datetime input with date and time pickers
    case "datetime":
      let userFriendlyDateTime = props.value.length > 0 ? new Date(props.value) : null;
      return (
        <InputContainer className={props.half && "half"}>
          <DatetimeInput
            theme={themeColors}
            showTimeSelect
            timeFormat="HH:mm" // set time format to 24-hour
            className={`input ${props.value.length > 0 ? "shrink" : ""}`}
            placeholderText={`${t(props.label)}${props.required ? " *" : ""}`}
            type={props.type}
            value={userFriendlyDateTime}
            selected={userFriendlyDateTime}
            minDate={new Date()} // set minimum date to today
            onChange={(event) => props.onChange(event, props.id, props.type)}
          />
          {props.error?.length ? (
            <>
              <Label theme={themeColors} className={`${!props.value.length > 0 ? "error shrink" : "error"}`}>
                {`${props.error}`}
              </Label>
              {props.value.length > 0 && <ShowDate>{formatDateTime(props.type, userFriendlyDateTime)}</ShowDate>}
            </>
          ) : (
            <>
              <Label theme={themeColors} className={`${!props.value.length > 0 ? "shrink" : ""}`}>
                <TickIcon />
                {`${t(props.label)}${props.required ? " *" : ""}`}
              </Label>
              {props.value.length > 0 && <ShowDate>{formatDateTime(props.type, userFriendlyDateTime)}</ShowDate>}
            </>
          )}
        </InputContainer>
      );
    // Render a file and image
    case "image":
    case "file":
      function formatSize(sizeInBytes) {
        if (sizeInBytes < 1024) {
          return sizeInBytes + " bytes";
        } else if (sizeInBytes < 1024 * 1024) {
          return (sizeInBytes / 1024).toFixed(2) + " KB";
        } else {
          return (sizeInBytes / (1024 * 1024)).toFixed(2) + " MB";
        }
      }
      const size = formatSize(props.value[0] ? props.value[0].size : 0);
      const handleButtonClick = () => {
        fileInputRef.current.click();
      };
      return (
        <FileContainer>
          <button onClick={handleButtonClick}>
            <UploadIcon />
            {t("upload", { label: t(props.label) }) + (props.required ? " *" : "") + (props.value.length > 0 ? ` : ${props.value[0].name} (${size})` : "")}
          </button>
          <Input ref={fileInputRef} style={{ display: "none" }} theme={themeColors} accept={props.type === "image" ? `image/*` : ``} className={`input ${props.value.length > 0 ? "shrink" : ""}`} placeholder={t(props.placeholder)} type={`file`} onChange={(event) => props.onChange(event, props.id, props.type)} />
          {props.error?.length > 0 && <ErrorMessage dangerouslySetInnerHTML={{ __html: props.error }} />}
        </FileContainer>
      );
    // Render a textarea
    case "textarea":
      return (
        <InputContainer className="textarea">
          {props.error?.length ? (
            <Label theme={themeColors} className={`${!props.value.length > 0 ? "error shrink" : "error"}`}>
              {props.error}
            </Label>
          ) : (
            <Label theme={themeColors} className={`${!props.value.length > 0 ? "shrink" : ""}`}>
              <TickIcon />
              {`${t(props.label)}${props.required ? " *" : ""}`}
            </Label>
          )}
          <TextArea theme={themeColors} className={`input ${props.value.length > 0 ? "shrink" : ""}`} placeholder={`${t(props.placeholder)}${props.required ? " *" : ""}`} value={props.value} onChange={(event) => props.onChange(event, props.id)} />
        </InputContainer>
      );
    // Render a submit button
    case "submit":
      return (
        <Button theme={themeColors} className="submit" disabled={props.disabled} type={props.type} onClick={props.onChange}>
          {props.value}
        </Button>
      );
    // Render a close button
    case "close":
      return (
        <Button theme={themeColors} className="close" type={props.type} onClick={props.onChange}>
          {props.value}
        </Button>
      );
    // Render a cehckbox
    case "checkbox":
      return (
        <InputContainer className="checkbox">
          <Label className="checkbox" theme={themeColors}>
            <CheckBox
              theme={themeColors}
              type={props.type}
              checked={props.value}
              onChange={() => {
                props.onChange(!props.value);
              }}
            ></CheckBox>
            <span dangerouslySetInnerHTML={{ __html: t(props.placeholder) }}></span>
          </Label>
        </InputContainer>
      );
    // Render a select box
    case "select":
      return <CustomSelect theme={themeColors} {...props} name={props.id} customClass={props.half ? "half" : ""} selected={props.value} onSelect={props.onChange}></CustomSelect>;
    default:
      return <></>;
  }
}
export default FormInput;
