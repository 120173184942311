import styled from "styled-components";

export const ParkingItem = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0;
  margin-bottom: 10px;
  border-radius: 10px;
  margin-right: 0px;
  margin-left: 0px;
  background-color: white;
  img {
    display: flex;
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-radius: 10px 10px 0 0;
    cursor: pointer;
  }
  article {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0;
  }
  h2 {
    margin: 0 0 10px 0;
    font-size: 16px;
    color: #1181c5;
  }
  p {
    margin: 5px 0 0 0;
    justify-content: left;
    display: flex;
    flex-wrap: nowrap;
  }
  .pricing {
    font-weight: bold;
  }
  svg {
    margin-right: 5px;
    margin-top: 3px;
    width: 10px;
  }
  button {
    font-weight: bold;
    margin: auto;
    width: 100%;
    border-radius: 10px;
  }
  @media (max-width: 768px) {
    margin-bottom: auto;
    button {
      font-weight: bold;
      margin: 10px;
      width: calc(100% - 20px);
      border-radius: 10px;
    }
    img {
      height: 150px;
      object-position: center;
    }
  }
`;
export const InfoBox = styled.div`
  display: flex;
  justify-content: center;
  padding: 20px;
  svg{
    margin-right:10px;
  }
`;
