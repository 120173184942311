// its middlewear to handle reducer call to update a state

import { getData } from "../../../backend/api";

const fetchCheckout = (id, data) => {
  return (dispatch) => {
    dispatch({
      type: "FETCH_CHECKOUT_LOADING",
    });
    getData(data, `parking/${id}?`)
      .then((response) => {
        if (response.status === 200) {
          dispatch({
            type: "FETCH_CHECKOUT_SUCCESS",
            payload: response.data,
          });
        } else {
          dispatch({
            type: "FETCH_CHECKOUT_ERROR",
            payload: "validationFailed",
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: "FETCH_CHECKOUT_ERROR",
          payload: error,
        });
      });
  };
};
const clearCheckout = () => {
  return (dispatch) => {
    dispatch({
      type: "CLEAR_CHECKOUT",
    });
  };
};
export { fetchCheckout, clearCheckout };
